@media screen and (max-width:800px) {
  .header {
    display: none;
  }
  .header-mobile {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 1.2rem;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 10000;

    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 0;
    }
    .line {
      fill: none;
      stroke: white;
      stroke-width: 6;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .line1 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    .line2 {
      stroke-dasharray: 60 60;
      stroke-width: 6;
    }
    .line3 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    .opened .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
    .opened .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 6;
    }
    .opened .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }

    &-menu {
      display: block;
      position: fixed;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.6);
      top: 100vh;
      z-index: 9990;
      transition: all .5s;
      transition-timing-function: ease-in-out;

      &.openMenu {
        top: 0;
      }

      &-wrap {
        width: 100%;
        padding: 2.1rem 0 .9rem;
        background-color: #0D0D0D;

        &-item {
          width: 100%;
          height: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: .32rem;
          line-height: .375rem;
          color: #CCCCCC;
          font-family: "SFB";
          cursor: pointer;

          &.active {
            color: #29FA51;
          }
        }

        a {
          width: 100%;
          height: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: .32rem;
          line-height: .375rem;
          color: #CCCCCC !important;
          font-family: "SFB";
        }
      }
    }

    &-blur {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      -webkit-backdrop-filter: blur(.1rem);
      backdrop-filter: blur(.1rem);
      z-index: 10001;
    }

    &-wrap {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 10002;
      padding: 0 .4rem;

      &-logo {
        width: 3.02rem;
        height: .48rem;
        @include bg("../assets/images/logo-header.png");
      }
    }
  }

  .first {
    padding-top: 9.2rem;
    height: 13.34rem;

    &-video {
      display: none;

      &-mobile {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }

    &-wrap {
      width: 6.2rem;

      &-title {
        margin-bottom: .27rem;
      }
    }
  }

  .app {
    padding-top: 1.1rem;

    &-title {
      width: 7.5rem;
      height: 1.44rem;
      margin-bottom: .88rem;
    }

    &-wrap {
      padding: 0 .54rem;

      &-item {
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 1.6rem;

        &:nth-child(even) {
          flex-direction: column-reverse;
        }

        &-video {
          width: 5.06rem;
          height: 8.7rem;
          padding: .17rem .69rem .17rem .17rem;
        }

        &-container {
          width: 100%;
          padding-top: 0;

          &-title {
            text-align: center;
            margin-bottom: .24rem;
          }

          &-content {
            text-align: center;
            margin-bottom: .56rem;
          }
        }
      }
    }
  }

  .toolkit {
    padding-top: 1.2rem;
    padding-bottom: 0;
    background-image: none;

    &-title {
      width: 7.5rem;
      height: 1.44rem;
      margin-bottom: .88rem;
    }

    &-subtitle {
      width: 100%;
    }

    &-info {
      width: 100%;
      margin-bottom: .24rem;
      padding: 0 .54rem;
    }

    &-studio {
      width: 100%;
      margin-bottom: 1rem;
      flex-direction: column;
      align-items: center;
      padding: 0 .48rem;

      &-video {
        width: 6.54rem;
        height: 3.8rem;
        margin-bottom: .64rem;
      }

      &-introduce {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 50%;

        &-item {
          width: 50%;
          flex-direction: column;
          margin-bottom: .52rem;

          &:nth-child(odd) {
            padding-right: .16rem;
          }
          &:nth-child(even) {
            padding-left: .16rem;
          }

          &-icon {
            margin-right: 0;
            margin-bottom: .24rem;
          }

          &-container {
            text-align: center;
            justify-content: flex-start;
          }
        }
      }
    }

    &-kit {
      width: 100%;
      height: auto;
      padding: .8rem .54rem 0;
      display: flex;

      flex-direction: column;
      grid-row-gap: 0;

      &-item {
        align-items: flex-start;
        flex-direction: row;
        padding-top: 0;
        margin-bottom: .4rem;

        &:nth-child(2), &:nth-child(5), &:nth-child(8) {
          &::after {
            content: "";
            width: 0;
            height: 0;
          }
        }

        &:nth-child(1) {
          padding-top: .72rem;
          &::after {
            content: "Login Kit";
            position: absolute;
            left: 50%;
            top: 0;
            width: 100%;
            text-align: center;
            transform: translateX(-50%);
            font-size: .32rem;
            line-height: .36rem;
            color: #FFFFFF;
            font-family: "SP75";
          }
        }

        &:nth-child(4) {
          padding-top: .72rem;
          &::after {
            content: "Crypto Kit";
            position: absolute;
            left: 50%;
            top: 0;
            width: 100%;
            text-align: center;
            transform: translateX(-50%);
            font-size: .32rem;
            line-height: .36rem;
            color: #FFFFFF;
            font-family: "SP75";
          }
        }

        &:nth-child(7) {
          padding-top: .72rem;
          &::after {
            content: "Game Kit";
            position: absolute;
            left: 50%;
            top: 0;
            width: 100%;
            text-align: center;
            transform: translateX(-50%);
            font-size: .32rem;
            line-height: .36rem;
            color: #FFFFFF;
            font-family: "SP75";
          }
        }

        &-image {
          margin-bottom: 0;
          margin-right: .16rem;
        }

        &-content {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        &-title {
          text-align: left;
        }

        &-content {
          text-align: left;
        }
      }
    }
  }

  .roadmap {
    padding-bottom: 1.3rem;

    &-title {
      width: 7.5rem;
      height: 1.44rem;
      margin-bottom: .61rem;
    }

    &-wrap {
      display: none;
    }

    .roadmap-wrap-mobile {
      display: block;
      width: 100vw;
      padding: 0 .68rem;

      &-container {
        width: 100%;
        margin-bottom: .52rem;

        &-year {
          text-align: center;
          font-size: .8rem;
          line-height: .93rem;
          font-family: "MEB";
          color: #000000;
          -webkit-text-stroke: .03rem #333333;
          text-stroke: .03rem #333333;
        }

        &-box {
          &-item {
            width: 100%;
            height: 2.08rem;
            background-color: #232323;
            margin-bottom: .08rem;
            border-radius: .08rem;
            display: flex;
            padding: .2rem;

            &:nth-child(1) {
              border-top-left-radius: .64rem;
              border-top-right-radius: .64rem;
            }

            &:nth-last-child(1) {
              border-bottom-left-radius: .64rem;
              border-bottom-right-radius: .64rem;
            }

            &-month {
              width: .98rem;
              height: .98rem;
              background-color: #000000;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: .32rem;
              color: #29FA51;
              font-family: "SP55";
              margin-right: .48rem;
            }

            &-content {
              flex: 1;
              padding-top: .12rem;

              p {
                font-size: .28rem;
                line-height: .32rem;
                color: #CCCCCC;
                margin-bottom: .24rem;

                &:nth-last-child(1) {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    display: none;
  }
  .footer-mobile {
    display: block;
    width: 100vw;
    padding: .56rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0D0D0D;

    &-title {
      font-size: .28rem;
      line-height: .32rem;
      color: #FFFFFF;
      font-family: "SFM";
      margin-bottom: .52rem;
    }

    &-social {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: .68rem;

      &-icon {
        width: .64rem;
        height: .64rem;
      }

      .instagram {
        @include bg("../assets/images/icon-instagram.png");
      }
      .twitter {
        @include bg("../assets/images/icon-twitter.png");
        margin: 0 .8rem;
      }
      .discord {
        @include bg("../assets/images/icon-discord.png");
      }
    }

    &-logo {
      width: 1.8rem;
      height: 1.14rem;
      margin-bottom: .28rem;
      @include bg("../assets/images/logo-footer.png");
    }

    &-copyright {
      p {
        font-size: .2rem;
        line-height: .23rem;
        color: #333333;
        font-family: "SFL";
        margin-bottom: .16rem;
      }
    }
  }
}
