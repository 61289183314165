@import 'mixin.scss';

@import 'style.scss';
@import 'style_mobile.scss';

* {
  box-sizing: border-box;
  cursor: url("../assets/arrow.ico"), default !important;
}

html, body {
  background-color: #000000;
}

a, a:link, a:visited, a:hover, a:active{
  text-decoration: none;
  color:inherit;
}

p {
  margin: 0;
  padding: 0;
}

.screen-loading-wrap {
  position: relative;
  z-index: 100004;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.screen-loading-wrap-loading {
  width: 180px;
  height: 4px;
  background: #1B1B1B;
  border-radius: 14px 14px 14px 14px;
  margin-top: .32rem;
  position: relative;
}

.screen-loading-wrap-loading-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 4px;
  background: #808080;
  border-radius: 14px 14px 14px 14px;
  transition: all 0.1s;
}
