.header {
  width: 100vw;
  height: .8rem;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 2.6rem;
  z-index: 10000;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &-blur {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(.1rem);
	  backdrop-filter: blur(.1rem);
    z-index: 10001;
  }

  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10002;

    &-logo {
      width: 2.21rem;
      height: .36rem;
      @include bg('../assets/images/logo-header.png');
      cursor: pointer;
    }

    &-nav {
      display: flex;
      align-items: center;

      &-item {
        font-size: .18rem;
        line-height: .22rem;
        color: #CCCCCC;
        font-family: "SFB";
        margin-right: .6rem;
        cursor: pointer;

        &:nth-last-child(1) {
          margin-right: 0;
        }

        &:hover {
          color: #29FA51;
        }

        &.active {
          color: #29FA51;
        }
      }
    }
  }
}
.header-mobile {
  display: none;

  &-menu {
    display: none;
  }
}

.first {
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 7.6rem;

  &-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    &-mobile {
      display: none;
    }
  }

  &-wrap {
    position: relative;
    z-index: 2;
    width: 8.2rem;
    margin-bottom: .92rem;

    &-title {
      position: relative;
      font-size: .36rem;
      line-height: .42rem;
      color: #CCCCCC;
      margin-bottom: .4rem;
      text-align: center;
      font-family: "JR";

      // &::after {
      //   content: "";
      //   width: .38rem;
      //   height: .38rem;
      //   position: absolute;
      //   right: .8rem;
      //   top: .16rem;
      //   @include bg("../assets/images/icon-arrow.png");
      // }
    }

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .28rem;
      line-height: .32rem;
      color: #999999;
      font-family: "SP55";

      &-icon {
        @include bg('../assets/images/logo-first.png');
        width: 1.8rem;
        height: .2rem;
      }
    }
  }
}

.app {
  width: 100vw;
  padding-top: .8rem;

  &-title {
    width: 9.6rem;
    height: 2rem;
    margin: 0 auto;
    @include bg("../assets/images/title-app.png");
    margin-bottom: .8rem;
  }

  &-wrap {
    width: 100%;
    padding: 0 3.8rem;

    &-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.2rem;

      &:nth-child(odd) {
        .app-wrap-item-video {
          @include bg("../assets/images/bg-video.png");
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;
        .app-wrap-item-video {
          @include bg("../assets/images/bg-video-2.png");
        }
      }

      &-video {
        padding: .17rem .53rem .17rem .17rem;
        width: 4.44rem;
        height: 7.78rem;

        video {
          border-radius: .04rem;
        }
      }

      &-container {
        width: 6.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-title {
          @include title;
          margin-bottom: .32rem;
        }

        &-content {
          @include content;
        }
      }
    }
  }
}

.toolkit {
  width: 100vw;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  background-image: url("../assets/images/bg-grid.png");
  background-size: 1.2rem 1.2rem;
  background-repeat: repeat;

  &-title {
    width: 9.6rem;
    height: 2rem;
    margin: 0 auto .6rem;
    @include bg("../assets/images/title-toolkit.png");
  }

  &-subtitle {
    width: 10rem;
    @include title;
    margin: 0 auto;
    margin-bottom: .24rem;
    text-align: center;
  }

  &-info {
    width: 10rem;
    text-align: center;
    @include content;
    margin: 0 auto;
    margin-bottom: .88rem;
  }

  &-studio {
    width: 13.2rem;
    margin: 0 auto 1.8rem;
    display: flex;
    justify-content: space-between;

    &-video {
      width: 7rem;
      height: 4.1rem;

      &-img {
        width: 100%;
        height: 100%;
        @include bg("../assets/images/studio.png");
      }
    }

    &-introduce {
      width: 5.6rem;

      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: .28rem;

        &-icon {
          width: .8rem;
          height: .8rem;
          margin-right: .3rem;
        }

        &-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          &-title {
            @include title2;
            margin-bottom: .04rem
          }

          &-content {
            @include content2;
          }
        }

        .avatar {
          @include bg("../assets/images/icon-avatar.png");
        }
        .world {
          @include bg("../assets/images/icon-world.png");
        }
        .item {
          @include bg("../assets/images/icon-item.png");
        }
        .live {
          @include bg("../assets/images/icon-live.png");
        }
      }
    }
  }

  &-kit {
    width: 10.85rem;
    height: 13.3rem;
    margin: 0 auto 1.2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 1.2rem;
    grid-column-gap: .98rem;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-top: .53rem;

      &:nth-child(2) {
        &::after {
          content: "Login Kit";
          position: absolute;
          left: 50%;
          top: 0;
          width: 100%;
          text-align: center;
          transform: translateX(-50%);
          font-size: .32rem;
          line-height: .37rem;
          color: #FFFFFF;
          font-family: "SP75";
        }
      }

      &:nth-child(5) {
        &::after {
          content: "Crypto Kit";
          position: absolute;
          left: 50%;
          top: 0;
          width: 100%;
          text-align: center;
          transform: translateX(-50%);
          font-size: .32rem;
          line-height: .37rem;
          color: #FFFFFF;
          font-family: "SP75";
        }
      }

      &:nth-child(8) {
        &::after {
          content: "Game Kit";
          position: absolute;
          left: 50%;
          top: 0;
          width: 100%;
          text-align: center;
          transform: translateX(-50%);
          font-size: .32rem;
          line-height: .37rem;
          color: #FFFFFF;
          font-family: "SP75";
        }
      }

      &-image {
        width: 1.8rem;
        height: 1.8rem;
        margin-bottom: .04rem;
      }

      &-title {
        @include title2;
        text-align: center;
        margin-bottom: .04rem
      }

      &-content {
        @include content2;
        text-align: center;
      }

      .profile {
        @include bg("../assets/images/image-profile.png");
      }
      .avatar {
        @include bg("../assets/images/image-avatar.png");
      }
      .social {
        @include bg("../assets/images/image-social.png");
      }
      .wallet {
        @include bg("../assets/images/image-wallet.png");
      }
      .swap {
        @include bg("../assets/images/image-swap.png");
      }
      .marketplace {
        @include bg("../assets/images/image-mraketplace.png");
      }
      .data {
        @include bg("../assets/images/image-data.png");
      }
      .character {
        @include bg("../assets/images/image-character.png");
      }
      .share {
        @include bg("../assets/images/image-share.png");
      }
    }
  }
}

.roadmap {
  width: 100vw;
  padding-top: 1.4rem;

  &-title {
    width: 9.6rem;
    height: 2rem;
    @include bg("../assets/images/title-roadmap.png");
    margin: 0 auto .8rem;
  }

  &-wrap {
    width: 100vw;
    // height: 1.95rem;
    position: relative;
    z-index: 10;

    &-mask {
      position: absolute;
      right: 0;
      bottom: .7rem;
      display: flex;
      width: 2.6rem;
      height: 4.76rem;
      display: flex;
      z-index: 12;
      // transform: translateY(50%);

      &-left {
        flex: 1;
        background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      }

      &-right {
        width: 2.1rem;
        height: 100%;
        background-color: #000000;
      }
    }

    &-mask2 {
      position: absolute;
      left: 0;
      bottom: .7rem;
      display: flex;
      width: 2.6rem;
      height: 4.76rem;
      display: flex;
      z-index: 12;
      // transform: translateY(50%);

      &-right {
        flex: 1;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      }

      &-left {
        width: 2.1rem;
        height: 100%;
        background-color: #000000;
      }
    }

    &-tips {
      display: flex;
      align-items: center;
      font-size: .18rem;
      line-height: .21rem;
      color: #666666;
      font-family: "SAR";
      position: absolute;
      right: 2.6rem;
      bottom: .84rem;

      &-icon {
        margin-left: .08rem;
        display: inline-block;
        width: .32rem;
        height: .32rem;
        @include bg('../assets/images/arrow-drag.png');
      }
    }

    // overflow-x: scroll;
    // scrollbar-width: none; /* Firefox */
    // -ms-overflow-style: none; /* IE 10+ */
    // &::-webkit-scrollbar {
    //   display: none; /* Chrome Safari */
    // }

    &-scroll {
      width: 100%;
      position: relative;
      padding-top: 1.9rem;
      padding-bottom: 1.8rem;
      overflow-x: scroll;
      overflow-y: visible;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
  }

  &-wrap-mobile {
    display: none;
  }

  &-container {
    width: 20.4rem;
    height: 1.95rem;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    cursor: url("../assets/drag.ico"), default !important;

    &-placeholder {
      width: 3.4rem;
      height: 100%;
    }

    &-item {
      cursor: url("../assets/drag.ico"), default !important;
      display: block;
      position: relative;
      width: 3.4rem;
      height: 1.95rem;
      border-left: .04rem solid #1A1A1A;
      background-image: linear-gradient(to right, #29FA51 0%, #29FA51 60%, transparent 50%);
      background-size: .15rem .01rem;
      background-position: top 0 left 0;
      background-repeat: repeat-x;
      padding-left: .12rem;
      padding-top: .65rem;

      &::after {
        content: attr(data-month);
        position: absolute;
        left: 0;
        top: -0.49rem;
        z-index: 10;
        border-radius: .85rem;
        // min-width: .98rem;
        padding: 0 .28rem;
        height: .98rem;
        background-color: #1A1A1A;
        font-size: .32rem;
        color: #29FA51;
        font-family: "SAM";
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
      }

      &::before {
        content: attr(data-year);
        position: absolute;
        left: -0.49rem;
        top: -1.85rem;
        z-index: 10;
        font-size: 1.04rem;
        font-family: "SP75";
        color: #000000;
        -webkit-text-stroke: .03rem #333333;
        text-stroke: .03rem #333333;
      }

      &-content {
        cursor: url("../assets/drag.ico"), default !important;
        font-size: .22rem;
        line-height: .25rem;
        color: #FFFFFF;
        font-family: "SAR";
        margin-bottom: .24rem;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.footer {
  width: 100vw;
  min-height: 3.75rem;
  background-color: #0D0D0D;
  padding: .8rem 2.6rem 1.1rem;
  display: flex;
  justify-content: space-between;

  &-left {
    &-logo {
      width: 1.74rem;
      height: 1.11rem;
      @include bg("../assets/images/logo-footer.png");
      margin-bottom: .28rem;
    }

    &-copyright {
      font-size: .18rem;
      line-height: .2rem;
      color: #666666;
      font-family: "SFL";
    }
  }

  &-right {
    display: flex;

    &-row {
      margin-right: 1.05rem;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      &-title {
        font-size: .18rem;
        line-height: .22rem;
        color: #FFFFFF;
        font-family: "SFM";
        margin-bottom: .2rem;
      }

      &-column {
        font-size: .16rem;
        line-height: .20rem;
        color: #CCCCCC;
        font-family: "SFL";
        margin-bottom: .16rem;

        &:hover {
          color: #29FA51;
        }
      }

      &-icons {
        display: flex;
        align-items: center;

        &-item {
          width: .3rem;
          height: .3rem;
          margin-right: .32rem;
        }

        .instagram {
          @include bg("../assets/images/icon-instagram.png");

          &:hover {
            @include bg("../assets/images/icon-instagram-active.png");
          }
        }
        .twitter {
          @include bg("../assets/images/icon-twitter.png");

          &:hover {
            @include bg("../assets/images/icon-twitter-active.png");
          }
        }
        .discord {
          @include bg("../assets/images/icon-discord.png");

          &:hover {
            @include bg("../assets/images/icon-discord-active.png");
          }
        }
      }
    }
  }
}
.footer-mobile {
  display: none;
}
