@mixin bg($url, $position: center, $size: 100% 100%, $repeat: no-repeat) {
  background-size: $size;
  background-position: $position;
  background-repeat: no-repeat;
  background-image: url($url);
}

@mixin title {
  font-size: .54rem;
  line-height: .60rem;
  color: #FFFFFF;
  font-family: "SP75";

  @media screen and (max-width:800px) {
    font-size: .36rem;
    line-height: .4rem;
  }
}

@mixin content {
  font-size: .24rem;
  line-height: .34rem;
  color: #CCCCCC;
  font-family: "SAR";

  @media screen and (max-width:800px) {
    font-size: .28rem;
    line-height: .42rem;
  }
}

@mixin title2 {
  font-size: .24rem;
  line-height: .28rem;
  color: #FFFFFF;
  font-family: "SP45";

  @media screen and (max-width:800px) {
    font-size: .32rem;
    line-height: .375rem;
  }
}

@mixin content2 {
  font-size: .22rem;
  line-height: .24rem;
  color: #808080;
  font-family: "SAR";

  @media screen and (max-width:800px) {
    font-size: .24rem;
    line-height: .28rem;
    color: #999999;
  }
}
