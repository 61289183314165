@font-face {
  font-family: SP75;
  src: url('./Shapiro-75HeavyText.otf') format("opentype");
}

@font-face {
  font-family: SP55;
  src: url('./shapiro-55-middle-text.ttf');
}

@font-face {
  font-family: SP45;
  src: url('./shapiro-45-welter-text.ttf');
}

@font-face {
  font-family: SAR;
  src: url('./Sansation_Regular.ttf');
}

@font-face {
  font-family: SFL;
  src: url('./SFProText-Light.ttf');
}

@font-face {
  font-family: SFM;
  src: url('./SFProText-Medium.ttf');
}

@font-face {
  font-family: SFB;
  src: url('./SFProText-Bold.ttf');;
}

@font-face {
  font-family: JR;
  src: url('./Joyride-Round.otf') format("opentype");
}

@font-face {
  font-family: MEB;
  src: url('./MonumentExtended-Black.ttf');
}
